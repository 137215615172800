import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import {
  HeroWrapper,
  Recognition,
  Cards,
  Team,
  Community,
  CTA,
} from "./components"

const background = css`
  background-color: ${colors.darkBackground};
  min-height: 100vh;
  padding-bottom: 100px;
  margin-top: -90px;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperInner = css`
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
`

function About({ data }) {
  return (
    <div css={background}>
      <HeroWrapper data={data} />
      <Recognition />
      <Cards />
      <div css={wrapperInner}>
        <Team data={data} />
        <Community />
        <CTA titleText="Want to get in touch?" buttonText="Contact Us" />
      </div>
    </div>
  )
}

export default About
