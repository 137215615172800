import React from "react"
import { graphql } from "gatsby"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { CompanyStructuredData } from "../components/structured-data"
import ogImage from "images/seo/about.jpeg"

import About from "../views/about"

const AboutPage = ({ data }) => (
  <div>
    <SEO
      title="About"
      description="Smokescreen is a global pioneer in deception-based threat defence technology. Our solution helps the world's most targeted organisations detect threats."
      image={ogImage}
    />
    <CompanyStructuredData />
    <PageLayout options={{ headingBackground: false }}>
      <About data={data} />
    </PageLayout>
  </div>
)

export const query = graphql`
  query {
    teamImages: allFile(filter: { relativeDirectory: { in: "about/team" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    heroImage: file(relativePath: { eq: "about/presence-map-2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1085, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default AboutPage
