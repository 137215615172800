import React from "react"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import _get from "lodash/get"

import { logError } from "src/util"
import { colors } from "css/theme"
import { h2 } from "css/primitives"

const TEAM = [
  {
    image: "sahir-2x",
    name: "SAHIR HIDAYATULLAH",
    role: "Chief Executive Officer",
  },
  {
    image: "raviraj-2x",
    name: "RAVIRAJ DOSHI",
    role: "Chief Operating Officer",
  },
  {
    image: "bhavesh-2x",
    name: "BHAVESH KOTHARI",
    role: "Chief Technology Officer",
  },
  {
    image: "kalidas-2x",
    name: "KALIDAS SURAPANENI",
    role: "Chief Revenue Officer",
  },
  {
    image: "reshad-2x",
    name: "RESHAD PATUCK",
    role: "Chief Research Officer",
  },
  {
    image: "rishika-2x",
    name: "Rishika Mehrotra",
    role: "Chief Marketing Officer",
  },
  {
    image: "sudarshan-2x",
    name: "SUDARSHAN PISUPATI",
    role: "Chief Technology Evangelist",
  },
  {
    image: "abhishek-2x",
    name: "ABHISHEK PARIKH",
    role: "VP - Strategic Initiatives",
  },
  {
    image: "preekshit",
    name: "Preekshit Gupta",
    role: "Sales Director - APAC & MEA",
  },
  {
    image: "jacinta-2x",
    name: "JACINTA FERNANDO",
    role: "Head - Customer Success",
  },
  {
    image: "amir-2x",
    name: "AMIR MOIN",
    role: "Head - Product",
  },
]

const wrapper = css`
  padding-top: 75px;
  margin-bottom: 25px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 991px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`
const heading = [
  h2,
  css`
    margin-bottom: 1.5rem;
  `,
]

const description = css`
  opacity: 0.7;
  color: white;
  font-size: 18px;
  line-height: 1.55;
  max-width: 760px;
  margin-bottom: 4rem;
  @media (max-width: 479px) {
    font-size: 1rem;
  }
`

const team = css`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    margin-right: -3rem;
    margin-left: -2rem;
  }
`

const member = css`
  width: 33%;
  padding-right: 1rem;
  display: flex;
  margin-bottom: 75px;
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 650px) {
    width: 50%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 37px;
    text-align: center;
  }
`

const memberImage = css`
  width: 80px;
  height: 80px;
  margin-right: 1rem;
  border-radius: 50%;
  img {
    max-width: 80px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
  @media (max-width: 650px) {
    margin-bottom: 1rem;
  }
`

const memberContent = css``

const memberName = css`
  color: ${colors.brightBlue};
  letter-spacing: 0.67px;
  font-size: 12px;
  font-family: var(--heading);
  margin-bottom: 4px;
  text-transform: uppercase;
`

const memberRole = css`
  color: white;
  opacity: 0.7;
  font-size: 1rem;
  line-height: 2;
  @media (max-width: 479px) {
    line-height: 1.4;
  }
`

function Team({ data }) {
  return (
    <div css={wrapper}>
      <h2 css={heading}>Our team, your team</h2>
      <p css={description}>
        Meet the folks behind the product and our customers’ success. These
        people work at Smokescreen, but they also work for you. This team is the
        reason our customer support is the stuff of legend. They’re here to
        help, even if you’re not a customer yet.
      </p>
      <div css={team}>
        {TEAM.map((person, index) => {
          let image =
            (_get(data, "teamImages.edges") || []).filter((edge) => {
              return _get(edge, "node.name") === person.image
            }) || {}
          image = _get(image, "[0].node.childImageSharp.fluid")
          return (
            <div key={index} css={member}>
              <div css={memberImage}>
                <Img
                  fluid={image}
                  loading="lazy"
                  alt={`Black and White photo of ${person.name}`}
                  onError={() => {
                    const error = new Error("Team image didn't load")
                    logError(error)
                  }}
                />
              </div>
              <div css={memberContent}>
                <div css={memberName}>{person.name}</div>
                <div css={memberRole}>{person.role}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Team
