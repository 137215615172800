import React from "react"
import { css } from "@emotion/core"

import { radius, colors } from "css/theme"
import { h2, h3 } from "css/primitives"

import OpenSourceIcon from "images/about/open-source-icon.svg"
import TyreIcon from "images/about/tyre-icon.png"

const heading = [
  h2,
  css`
    text-align: center;
    margin-bottom: 40px;
  `,
]

const cards = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;
  @media (max-width: 1199px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const card = css`
  background-color: ${colors.brighterBackground};
  border-radius: ${radius.big};
  width: calc(50% - 1rem);
  padding: 55px;
  padding-top: 26px;
  @media (max-width: 991px) {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 600px;
  }
  @media (max-width: 479px) {
    padding: 26px;
  }
`

const cardIcon = css`
  height: 77px;
  margin-bottom: 1rem;
  img {
    max-height: 100%;
  }
`
const cardTitle = [
  h3,
  css`
    line-height: 1.5;
  `,
]

const cardDescription = css`
  font-size: 18px;
  line-height: 1.55;
  opacity: 0.7;
  color: white;
`

function Community() {
  return (
    <div>
      <h2 css={heading}>Giving Back</h2>
      <div css={cards}>
        <div css={card}>
          <div css={cardIcon}>
            <img src={OpenSourceIcon} alt="" />
          </div>
          <h3 css={cardTitle}>Open source</h3>
          <p css={cardDescription}>
            Smokescreen is proud supporter of multiple open-source projects. Our
            base platform is a hardened version of FreeBSD, and we contribute
            back to the BSD community in a number of ways.
          </p>
        </div>
        <div css={card}>
          <div css={cardIcon}>
            <img src={TyreIcon} alt="Tyre icon" />
          </div>
          <h3 css={cardTitle}>The Climate Crisis</h3>
          <p css={cardDescription}>
            We aim to be completely carbon neutral and pledge to plant trees to
            offset our carbon footprint.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Community
