import React from "react"
import { css } from "@emotion/core"

import SCMedia from "images/about/sc-media-1x.png"
import Gartner from "images/about/gartner-cool-vendor-1x.png"
import Emerge from "images/about/emerge-50-1x.png"

import { colors } from "css/theme"

const wrapper = css`
  background-color: black;
  padding-top: 80px;
  padding-bottom: 80px;
`

const blockWrapper = css`
  border-top: 1px solid ${colors.grayBorder};
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 75px;
`

const blocks = css`
  display: flex;
  justify-content: space-between;
  max-width: 676px;
  margin-left: auto;
  margin-right: auto;
`

const block = css`
  padding-left: 1rem;
  padding-right: 1rem;
`

function Recognition() {
  return (
    <div css={wrapper}>
      <div css={blockWrapper}>
        <div css={blocks}>
          <div css={block}>
            <img src={SCMedia} alt="SC media" />
          </div>
          <div css={block}>
            <img src={Gartner} alt="Gartner Cool Vendor" />
          </div>
          <div css={block}>
            <img src={Emerge} alt="Emerge 50" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recognition
