import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { routes } from "src/constants"
import { colors } from "css/theme"
import { h2, btn } from "css/primitives"

const cta = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const title = [
  h2,
  css`
    margin-right: 1rem;
    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  `,
]

const button = [
  btn,
  css`
    flex-shrink: 0;
    color: white;
    letter-spacing: 1px;
    border: 2px solid ${colors.darkOrange};
    background-color: transparent;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${colors.darkOrange};
      color: white;
    }
  `,
]

function CTA({ titleText, buttonText }) {
  return (
    <div css={cta}>
      <h2 css={title}>{titleText}</h2>
      <Link to={routes.contact} css={button}>
        {buttonText}
      </Link>
    </div>
  )
}

export default CTA
