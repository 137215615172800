import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { h2 } from "css/primitives"

const cards = css`
  display: flex;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1099px) {
    display: block;
  }
`

const leftCard = css`
  background-color: ${colors.brighterBackground};
  width: 50%;
  padding: 86px;
  @media (max-width: 1099px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 44px;
  }
`

const rightCard = css`
  background-color: ${colors.brightestBackgorund};
  width: 50%;
  padding: 86px;
  @media (max-width: 1099px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 44px;
  }
  @media (max-width: 479px) {
    padding: 30px;
  }
`

const content = css`
  max-width: 480px;
  margin-right: auto;
  margin-left: auto;
`

const heading = [
  h2,
  css`
    margin-bottom: 1rem;
  `,
]

const description = css`
  color: white;
  opacity: 0.7;
  font-size: 18px;
  line-height: 1.55;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`

function Cards() {
  return (
    <div css={cards}>
      <div css={leftCard}>
        <div css={content}>
          <h2 css={heading}>Our Roots</h2>
          <p css={description}>
            Before Smokescreen, our founding team ran high-end red team and
            breach readiness assessments for some of the world’s largest
            companies, showing them how their security systems could be
            bypassed.
          </p>
          <p css={description}>
            They were good at it and it was fun. After a while, though, they
            grew frustrated with the fact that they were consistently able to
            break in despite the ‘state-of-the-art’ solutions in their
            customers’ environments.
          </p>
          <p css={description}>
            They kept asking themselves, “what would catch guys like us?”
          </p>
          <p css={description}>Smokescreen is the answer to that question.</p>
        </div>
      </div>
      <div css={rightCard}>
        <div css={content}>
          <h2 css={heading}>Our story</h2>
          <p css={description}>
            While deception has been around for a while, it was still mostly
            academic and experimental due to the complexity of making it work at
            scale. But the idea stuck.
          </p>
          <p css={description}>
            We began hand-crafting digital traps for highly targeted
            organisations that could not afford a breach; places where other
            defences had regularly been defeated. It worked — and how! They
            caught things that were invisible to ‘state-of-the-art’ products.
          </p>
          <p css={description}>
            This was our genesis; our ‘a-ha’ moment. Smokescreen was born.
          </p>
          <p css={description}>
            We focus on making targeted threat detection simple, so even smaller
            security teams can punch above their weight.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Cards
