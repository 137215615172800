import React, { useState } from "react"
import { css } from "@emotion/core"
import _get from "lodash/get"
import Img from "gatsby-image"

import { logError } from "src/util"
import { colors } from "css/theme"
import { h2 } from "css/primitives"

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  background-image: radial-gradient(
    ellipse at top,
    ${colors.gradientStartColor} 0%,
    black 80%
  );
  padding-top: 182px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
  }
`

const heading = [
  h2,
  css`
    margin-bottom: 1rem;
    text-align: center;
  `,
]

const description = css`
  font-size: 20px;
  line-height: 1.6;
  color: white;
  opacity: 0.5;
  text-align: center;
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 70px;
  @media (max-width: 767px) {
    font-size: 17px;
  }
`

function HeroWrapper({ data }) {
  const heroImage = _get(data, "heroImage.childImageSharp.fluid")
  const [imageStyles, setImageStyles] = useState({ opacity: 0 })
  return (
    <div css={wrapper}>
      <h1 css={heading}>Your life’s work, secured by ours</h1>
      <p css={description}>
        At Smokescreen, we build simple and intuitive solutions that enable
        companies to predict threats, detect attacks, and respond to breaches.
        Our products have helped security teams around the world detect threats
        where others have failed.
      </p>
      <Img
        fluid={heroImage}
        loading="eager"
        style={{ width: "100%", maxWidth: "1085px", ...imageStyles }}
        alt="Smokescreen's presence in different countries on a map"
        fadeIn={false}
        onLoad={() => {
          setImageStyles({ opacity: 1 })
        }}
        onError={() => {
          const error = new Error("About page hero image didn't load")
          logError(error)
        }}
      />
    </div>
  )
}

export default HeroWrapper
